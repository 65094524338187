body {
  margin: 0;
  font-family: 'Helvetica';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
    
@font-face {
  font-family: "Graphic";
  src: local("Graphic"),
    url("./fonts//Graphic//Graphik-Regular.otf") format("opentype");
}
